import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Reservation from "../../middleware/reservation";
import { LoadingIndicator } from "../../components/commons";

const ConfirmReservation = () => {
  const [searchParam] = useSearchParams();
  const reservationId = Number(searchParam.get("reservation-id")) || null;
  const reservationToken = searchParam.get("reservation-token") || "";

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const confirmReservation = async () => {
      if (!reservationId || !reservationToken) {
        setError("Invalid reservation ID.");
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        const response = await Reservation.confirm({
          reservationId,
          reservationToken,
        });
        console.log(response);
        setIsSuccess(response.data.success);
      } catch (err) {
        console.error("Error confirming reservation:", err);
        setError("Failed to confirm your reservation. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    confirmReservation();
  }, [reservationId, reservationToken]);

  if (isLoading) {
    return (
      <LoadingIndicator
        loaderClass="component-loader"
        spinnerClass="spinner__path--component"
      />
    );
  }

  const renderMessage = () => {
    if (error) {
      return <h3 className="title">{error}</h3>;
    }

    return (
      <h3 className="title">
        {isSuccess
          ? "You have successfully confirmed your reservation with Diamond Hotel Kipe."
          : "There was an issue in confirming your reservation."}
      </h3>
    );
  };

  return (
    <section className="payment-area section-bg section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-box payment-received-wrap mb-0">
              <div className="form-content">
                <div className="payment-received-list">
                  <div className="d-flex align-items-center">
                    <i className="la la-check icon-element flex-shrink-0 mr-3 ml-0"></i>
                    <div>{renderMessage()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmReservation;
