import { Link } from "react-router-dom";
import error from "../../assets/images/error.jpg";
import PathConstants from "../../routes/pathConstants";
const Error = () => {
  return (
    <section className="error-area section--padding text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto">
            {/* Error Illustration */}
            <div className="error-img">
              <img src={error} alt="Error Illustration" />
            </div>
            {/* Error Message */}
            <div className="section-heading padding-top-35px">
              <h2 className="sec__title mb-0">
                An Error Happened in Performing Your Request
              </h2>
              <p className="sec__desc pt-3">
                We're sorry, but it appears there was an error in fulfilling
                your request.
              </p>
            </div>
            {/* Back to Home Button */}
            <div className="btn-box padding-top-30px">
              <Link to={PathConstants.HOME}>
                <i className="la la-reply me-1"></i> Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
