const PathConstants = {
  HOME: "/",
  ROOM_LIST: "/rooms",
  ROOM_DETAILS: "/room-details",
  GALLERY: "/gallery",
  CONTACT: "/contact",
  BOOKING_SUBMISSION: "/booking-submission",
  BOOKING_COMPLETED: "/booking-completed",
  RESERVATION_REQUEST_DISPLAY: "/reservation-request-display",
  CONFIRM_RESERVATION: "/confirm-reservation",
  CANCEL_RESERVATION: "/cancel-reservation",
};
export default PathConstants;
