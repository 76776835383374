import httpsCommon from "./https-common";
import getEnvVariable from "./env";

const tokenEndpoint = "/api/token";
const grantType = "client_credentials";
const requestData = {
  grant_type: grantType,
  client_id: getEnvVariable("REACT_APP_DHK_TOKEN_CLIENT_ID"),
  client_secret: getEnvVariable("REACT_APP_DHK_TOKEN_CLIENT_SECRET"),
};
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};
export const getToken = () => {
  const requestBody = new URLSearchParams(requestData).toString();
  return httpsCommon.post(tokenEndpoint, requestBody, {
    headers,
  });
};
