import https from "../https-common";
import { getToken } from "../auth";

const reservationEndpoint = "/api/v1/reservations";
const tokenEndpoint = "/tokens";
export default class Reservation {
  static async create(reservation) {
    const getTokenResponse = await getToken();
    const { access_token } = getTokenResponse.data;
    https.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    return https.post(reservationEndpoint, reservation);
  }
  static async getReservationToken() {
    const getTokenResponse = await getToken();
    const { access_token } = getTokenResponse.data;
    https.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    return https.get(reservationEndpoint + tokenEndpoint);
  }
  static async getSummary(reservationId) {
    const getTokenResponse = await getToken();
    const { access_token } = getTokenResponse.data;
    https.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    return https.get(`${reservationEndpoint}/summary/${reservationId}`);
  }
  static async confirm(reservation) {
    const getTokenResponse = await getToken();
    const { access_token } = getTokenResponse.data;
    https.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    return https.post(`${reservationEndpoint}/confirm`, reservation, {
      headers: { "Content-Type": "application/json" },
    });
  }

  static async cancel(reservation) {
    const getTokenResponse = await getToken();
    const { access_token } = getTokenResponse.data;
    https.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    return https.post(`${reservationEndpoint}/cancel`, reservation, {
      headers: { "Content-Type": "application/json" },
    });
  }
}
